import req from './request'

const DEFAULT_ENDPOINTS = {
    dashboard: '/dashboard',
    department: '/department',
    departmentRef:'/department/reference',
    park: '/park',
    authPark: '/park/auth',
    payment: '/dashboard/commissions',
    oferta: '/dashboard/offer',
    offerReference: '/dashboard/offer/reference',
    provider: '/provider',
    providerDetail: '/provider/detail',
    providerLog: '/provider/log',
    providerService: '/provider-service',
    providerServiceList: '/provider-service/providers',
    serviceByProvider: '/provider-service/service-by-provider',
    role: '/role',
    roleItems: '/role/items',
    sourceProvider:'/source-provider',
    suppliers: '/provider/suppliers',
    user: '/user',
    chat: '/platforms/chat',
    chatReference: '/platforms/chat/reference',
    chatSettings: '/platforms/chat/settings',
    applicationInstance: '/application-instance',
    applicationInstanceReference: '/application-instance/reference',
    applicationSetting: '/application-setting',
    applicationSettingRefference: '/application-setting/reference',
    applicationSettingWelcomeText: '/application-setting/welcome-text',
    sourceTarget:'/application-instance/source-target/',
    dashboardReference: '/dashboard/reference',

    applicationRevision:'/application-revision',
    security: '/dashboard/finance-security',
    userChangePass: '/user/change-password',
    spends: '/spends',
    rolesReference: '/dashboard/roles-reference',
    allert: '/company',
    ie: '/ie'
}

export default {
    providerDetail(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.providerDetail, param)
    },
    authPark(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.authPark, param)
    },
    providerLog(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.providerLog, param)
    },
    departmentRef(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.departmentRef, param)
    },
    dashboardReference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.dashboardReference, param)
    },
    sourceTarget(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.sourceTarget, param)
    },
    applicationSetting(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.applicationSetting, param)
    },
    applicationSettingRefference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.applicationSettingRefference, param)
    },
    chatSettings(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.chatSettings, param)
    },
    chatReference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.chatReference, param)
    },
    chat(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.chat, param)
    },
    dashboard(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.dashboard, param)
    },
    user(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.user, param)
    },
    department(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.department, param)
    },
    park(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.park, param)
    },
    provider(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.provider, param)
    },
    providerService(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.providerService, param)
    },
    providerServiceList(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.providerServiceList, param)
    },
    serviceByProvider(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.serviceByProvider, param)
    },
    role(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.role, param)
    },
    roleItems(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.roleItems, param)
    },
    sourceProvider(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.sourceProvider, param)
    },
    payment(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.payment, param)
    },
    suppliers(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.suppliers, param)
    },
    applicationInstance(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.applicationInstance, param)
    },
    applicationInstanceReference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.applicationInstanceReference, param)
    },

    applicationRevision(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.applicationRevision, param)
    },

    oferta(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.oferta, param)
    },

    offerReference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.offerReference, param)
    },

    applicationSettingWelcomeText(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.applicationSettingWelcomeText, param)
    },
    security(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.security, param)
    },
    userChangePass(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.userChangePass, param)
    },

    spends(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.spends, param)
    },

    rolesReference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.rolesReference, param)
    },

    allert(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.allert, param)
    },

    ie(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.ie, param)
    }
}