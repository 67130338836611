import api from '@api'
export default {
    data() {
        return {
            isEdit: false,
            queryApi: null,
            ready: false,
            isloading : false,
            isDictionary: false,
            error: null,
            // toastErrors: [],
            errorDictionary: {},
            success: {
                create: `Cоздан`,
                edit: `Обновлен`
            }
        }
    },
    
    methods: {
        init(callback) {
            this.isDictionary = JSON.stringify(this.errorDictionary) !== JSON.stringify({})
            
            this.queryApi = api.company[this.api]
            const query = this.$route.query
            this.isEdit = !!Object.keys(query).length 
            const param = this.isEdit? query.id : ''
         
            if(this.isEdit) {
                this._api = api
                api.company[this.api]('',[param]).then(data=>{
                    this.formData = data
                    this.ready = true
                    callback ? this[callback](data) : ''
                }).catch(error=>{
                    if(error.message) this.$toast.error(error.message)
                    console.error(error)
                })

                this.initBreadcrumb()
            } else {
                this.ready = true
                this.initBreadcrumb()
            }
        },
        query(options) {
            this.isloading = true
            return api.company[this.api](options.payload,options.param,options.method).then(e=>{
                const componentName = this.success.routerComponent
                const name = options.payload.name || ''
                const message = this.isEdit? this.success.edit : this.success.create

                this.$router.push({name:componentName})
                this.$toast.success(message.replace("${name}", `${name}`))
                return e
            }).catch(res=> {
                if(res.response) this.fillErrors(res.response)
                if(res.message) this.$toast.error(res.message)
                console.error(res)
                return res
            }).finally(()=>{ this.isloading = false })
        },
        fillErrors(error) {
            if(!error.length) return
            this.error = {}
            
            error.forEach(err=>{
                if(!error.length) return
                if(this.isDictionary) {
                    for (const key in this.errorDictionary) {
                        if (Object.hasOwnProperty.call(this.errorDictionary, key)) {
                            const element = this.errorDictionary[key];
                            if(err.field == key) err.field = element
                        }
                    }
                }
                console.log({
                    t:this,
                    $toastErrors: this.$toastErrors,
                    toastErrors: this.toastErrors
                });
                if(this.toastErrors && this.toastErrors.length) {
                    this.toastErrors.forEach(field => {
                        if(err.field == field) this.$toast.error(err.message) 
                    })
                }
                this.error[err.field] = err.message
            })
        },
        initBreadcrumb() {
            this.breadcrumb.push({
                title: this.lastCrumb.edit
            })
        }
    },
}
